<template>
  <div>
    <div class="v-wrap-bar">
      <div class="v-bar">
        <button @click="toggle=!toggle" class="toggle-nav">
          <span>☰</span>
        </button>
        <div class="title-bar"> {{ jumble.name }} - {{ getJumbledWord() }}</div>
      </div>
      <div v-if="toggle">
        <ul class="nav-list">
          <li v-if="listgames"><a :href="listgames">All puzzles</a></li>
          <li><a href="/about">Instructions</a></li>
          <!-- <li hidden v-if="jumble.name" @click="printGame">
            <span>Print Game</span>
          </li> -->
          <li v-if="jumble.name" @click="showSolution">
            <span>Show solution</span>
          </li>
          <li v-if="jumble.name" @click="toggle = false; reset.display = true">
            <span>Reset Game</span>
          </li>
          <li v-if="jumble.pdf_url" :href="game.pdf_url" target="_blank" @click="printGame()">
            <span>Print Game</span>
          </li>
          <li>
            <a href="/contact" target="_blank">Contact</a>
          </li>
          <!-- <li hidden><a :href="'mailto:' + email">Report a bug</a></li> -->
        </ul>
      </div>
    </div>
    <dia-choose :display="reset.display" :text="reset.text" :title="reset.title" @yes="clearGame(); reset.display=false"
      @no="reset.display=false"></dia-choose>
  </div>
</template>
<style>
  @import '../assets/css/style.css';
</style>

<script>
  import {
    mapState,
    mapGetters
  } from 'vuex';
  import diaChoose from '@/components/diaChoose.vue';
  import 'jspdf-autotable';

  export default {
    components: {
      diaChoose,
    },
    data: () => ({
      toggle: false,
      email: 'support@generaltechnical.com.au?subject=Auspac Media Targettime Issue',
      reset: {
        display: false,
        text: 'Are you sure you want to reset? All progress will be lost.',
        title: 'Reset game.',
      },
    }),
    computed: {
      ...mapState('jumble', ['jumble', 'listgames']),
      ...mapState(['sesh']),
      ...mapState(['modal']),
      ...mapGetters('jumble', ['getJumbledWord']),
      ...mapGetters('session', ['getUserAnswer']),
    },
    methods: {
      clearGame() {
        this.$store.dispatch('sesh/clear', this.jumble.name);
        this.toggle = false;
        window.location.reload()
      },
      showSolution() {
        this.$store.dispatch('modal/setModal', {
          display: true,
          title: `Solution for ${this.jumble.name}`,
          solution: this.jumble.answers,
          message: null,
          color: 'blue'
        })
      },
      async printGame() {
        this.$axios.get(`/targettimes/${this.jumble.id}/pdf`).then(res => {
          var fileURL = res.data.fileURL;
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${res.data.fileName}.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
        })
      },
    },
  };
</script>