<template>
  <div id="game-list" class="w-100">
    <ul class="items" v-for="item in listgames" :key="item.game_id">
        <li data-id="1">
          <a class="puzzle-link" :href="`/game/${item.game_id}`">
            <div class="icon">
              <img src="../assets/target-jumble-icon.png">
            </div>
            <div class="tile-info">
              <div>
                <strong>{{ now.subtract(1, 'days').format('D MMMM YYYY') }}</strong>
              </div>
              <div>
                <em>{{ item.game_name }}
                  <span class="author"> / {{ item.name }}</span>
                </em>
              </div>
              <i class="fa fa-angle-right"></i>
            </div>
          </a>
        </li>
    </ul>
  </div>
</template>


<script>
import moment from 'moment';

export default {
  name: 'ListGame',
  data() {
    return {
      listgames: [],
      now: moment().add(1, 'days'),
    };
  },
  async mounted() {
    const response = await this.$axios.get(`/listgames?client=${this.$route.params.client}&game=targettime`);
    const { data } = response;
    this.listgames = data;
  },
};
</script>
